import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Hero1 from "./pages/hero1";
import Hero2 from "./pages/hero2";
import Home from "./pages";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hero1" element={<Hero1 />} />
          <Route path="/hero2" element={<Hero2 />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
