import React from "react";

const NavSelect = () => {
  return (
    <div className="NavSelect flex items-center align-middle justify-evenly h-[80vh]">
      <div className="SelectH1 bg-slate-200">
        <a href="./hero1">
          <img src="images/hero1_prev.png" alt="hero1 preview" />
        </a>
      </div>
      <div className="SelectH2">
        <a href="./hero2">
          <img src="images/hero2_prev.png" alt="hero2 preview" />
        </a>
      </div>
    </div>
  );
};

export default NavSelect;
