import "./hero.css";
import React, { useState, useCallback, useRef } from "react";
import { toPng } from "html-to-image";
import { ArrowDownTrayIcon, ArrowsPointingOutIcon, ArrowsUpDownIcon, ArrowsRightLeftIcon } from "@heroicons/react/24/solid";
import HomeBar from "../components/homebar";

const Hero2 = () => {
  const [image, setImage] = useState("images/hero1_default.jpg");
  function handleImgUpload(e) {
    const fileInput = document.getElementById("custom-img");
    const filePath = fileInput.value;
    const allowedFiles = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedFiles.exec(filePath)) {
      alert("Bitte wähle eine gültige Bilddatei (JPEG oder PNG) aus!");
      fileInput.value = "";
      return false;
    } else {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  }

  function handleTextChange() {
    const herotext = document.getElementById("hero2text");
    const inputtext = document.getElementById("custom-text").value;
    herotext.innerText = inputtext;
  }

  function handleTextResize() {
    const textsizein = document.getElementById("custom-text-size").value;
    const herotext = document.getElementById("hero2text");
    herotext.style.fontSize = textsizein + "px";
  }

  function handleImgScaling() {
    const inputscale = document.getElementById("img-scale").value;
    const imgscale = Math.round(inputscale);
    const heroimg = document.getElementById("hero2-bg-img");
    heroimg.style.height = imgscale + "px";
  }
  function handleImgPosX() {
    const inputxpos = document.getElementById("img-pos-x").value;
    const imgxpos = Math.round(inputxpos);
    const heroimg = document.getElementById("hero2-bg-img");
    heroimg.style.transform = "translateX(" + imgxpos + "px)";
  }
  function handleImgPosY() {
    const inputypos = document.getElementById("img-pos-y").value;
    const imgypos = Math.round(inputypos);
    const heroimg = document.getElementById("hero2-pattern");
    heroimg.style.transform = "translateY(" + imgypos + "px)";
  }

  const ref = useRef(null);

  const handleImgDownload = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "hero1.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  return (
    <>
      <HomeBar />
      <div className="Hero2">
        <div className="mt-10 w-full h-auto flex flex-col items-center justify-center">
          <div ref={ref} className="hero-main bg-[#e7d2b5] shadow-xl">
            <div className="herotext-cnt">
              <p id="hero2text" className="herotext z-30">
                Hero 2
              </p>
            </div>
            <svg id="stoerer-img" x="0px" y="0px" viewBox="0 0 300 300">
              <defs>
                <pattern id="hero2-pattern" patternUnits="userSpaceOnUse" width="500" height="300">
                  <image href={image} x="-25%" y="0" id="hero2-bg-img" />
                </pattern>
              </defs>
              <path
                fill="url(#hero2-pattern)"
                d="M48.7,48.8c-63.6,63.6-66.9,130.5,2.6,199.9c81.9,81.9,149.9,52.6,199.6,2.9
	c62.9-62.9,68.7-130.1-1.8-200.7C179.5-18.5,112.3-14.8,48.7,48.8"
              />
            </svg>
          </div>
          <div className="herogen-ui mt-10 w-[800px] flex flex-col">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col items-start w-4/5 me-5">
                <label htmlFor="custom-text">Hero Text</label>
                <textarea id="custom-text" type="text" onChange={handleTextChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-3" placeholder="Hier kommt der Text hin" />
              </div>
              <div className="flex flex-col w-1/5">
                <label htmlFor="custom-text-size">Textgrösse</label>
                <input type="number" defaultValue={40} onChange={handleTextResize} id="custom-text-size" className="shadow appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-3" />
              </div>
            </div>
            <div className="flex flex-col self-start mt-5">
              <label htmlFor="custom-img">Bilddatei</label>
              <input id="custom-img" type="file" accept=".png,.jpg,.jpeg" onChange={handleImgUpload} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-3" />
            </div>
            <label htmlFor="img-scale" className="self-start mt-5 flex flex-row">
              <ArrowsPointingOutIcon className="size-5 me-2" /> Bild-Skalierung
            </label>
            <input id="img-scale" type="range" max="900" min="100" defaultValue={300} onChange={handleImgScaling} className="w-full mt-3 self-start" />
            <label htmlFor="img-pos-x" className="self-start mt-5 flex flex-row">
              <ArrowsRightLeftIcon className="size-5 me-2" /> Bild-Position X
            </label>
            <input id="img-pos-x" type="range" max="400" min="-400" defaultValue={0} onChange={handleImgPosX} className="w-full mt-3 self-start" />
            <label htmlFor="img-pos-y" className="self-start mt-5 flex flex-row">
              <ArrowsUpDownIcon className="size-5 me-2" />
              Bild-Position Y
            </label>
            <input id="img-pos-y" type="range" max="200" min="-200" defaultValue={0} onChange={handleImgPosY} className="w-full mt-3 self-start" />
            <div className="herogen-ui-btns flex gap-3">
              <select disabled className="w-[64px] mt-3 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option>PNG</option>
                <option>JPEG</option>
                <option>SVG</option>
              </select>
              {/* prettier-ignore */}
              <button onClick={handleImgDownload} className="btn p-3 mt-3  bg-blue-500 rounded-md text-white font-bold flex flex-row">
              <ArrowDownTrayIcon className="size-5 me-2" />Download
            </button>
              {/* <button className="btn p-3 mt-3 bg-red-500 rounded-md text-white font-bold">Reset</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero2;
