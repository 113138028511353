import HomeBar from "../components/homebar";
import NavSelect from "../components/navselect";

const Home = () => {
  return (
    <>
      <HomeBar />
      <NavSelect />
    </>
  );
};
export default Home;
