import React from "react";
import { HomeIcon } from "@heroicons/react/16/solid";

const HomeBar = () => {
  return (
    <div className="homebar-container w-full h-[70px] bg-[#002DA5] flex flex-row px-5 items-center justify-between">
      <h3 className="text-white">HeroGen</h3>
      <div className="flex flex-row gap-5 items-center">
        <a className="text-white" href="/">
          <HomeIcon className="size-5" />
        </a>
        <p className="text-slate-400 text-xs">v1.0</p>
      </div>
    </div>
  );
};
export default HomeBar;
